<template>
  <div>
    <confirm-modal
      v-if="showModal"
      :title="modalTitle"
      :message="modalMessage"
      @confirmed="onConfirmed"
      @declined="onDeclined"
    />
    <nav-header
      @navigate-backwards="switchToMainEdit"
      :isEditPositionMap="showEditPositionMap"
      :title="navTitle"
    ></nav-header>
    <edit-position-map
      :collectionForm="collection"
      v-if="showEditPositionMap"
      @switch-to-main-edit="switchToMainEdit"
    ></edit-position-map>
    <div v-show="!showEditPositionMap">
      <div class="container">
        <div class="row mt-5">
          <div class="col">
            <p v-if="collection != null && !collection.deleted">
              {{
                this.$englishPreferred ? "Spontaneous count" : "Spontantælling"
              }}: {{ collection.date }} <br />
              {{ this.$englishPreferred ? "Start time" : "Starttid" }}:
              {{ collection.startTime }} <br />
              {{ this.$englishPreferred ? "Duration" : "Varighed" }}: 5:00
            </p>
            <div class="row mb-5">
              <div class="col">
                <p>
                  {{ this.$englishPreferred ? "Placement" : "Placering" }}:
                  <button
                    class="btn-xs btn-outline-secondary"
                    v-on:click="changePosition()"
                  >
                    {{ this.$englishPreferred ? "Change" : "Skift" }}
                  </button>
                </p>
                <div class="mb-4">
                  <div id="tinymapid"></div>
                </div>

                <div class="col text-center mb-4" v-if="collection != null">
                  <div class="input-group input-group-lg">
                    <label
                      for="time"
                      class="input-group-text"
                      id="inputGroup-sizing-lg"
                      >{{
                        $englishPreferred ? "Start time" : "Starttid"
                      }}</label
                    >
                    <input
                      v-if="collection.startTime != null"
                      id="time"
                      @change="setEndTime"
                      type="time"
                      v-model="collection.startTime"
                      class="form-control"
                    />
                  </div>
                </div>

                <div>
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    @click="toggleOptionals"
                  >
                    {{
                      showOptionals
                        ? this.$englishPreferred
                          ? "Hide optional data"
                          : "Skul valgfrit data om turen"
                        : this.$englishPreferred
                        ? "Fill optional data"
                        : "Tilføj valgfri data om turen"
                    }}
                  </button>
                </div>
                <div v-if="showOptionals && collection != null">
                  <div class="card card-body">
                    <h4 class="text-center mb-3">
                      {{ this.$englishPreferred ? "Optional" : "Valgfrit" }}
                    </h4>
                    <div class="mb-3 input-group">
                      <span class="input-group-text">{{
                        this.$englishPreferred ? "Tour notes" : "Turnoter"
                      }}</span>
                      <textarea
                        v-model="collection.notes"
                        class="form-control"
                      ></textarea>
                    </div>

                    <div class="mb-3 input-group">
                      <span class="input-group-text">{{
                        this.$englishPreferred
                          ? "Co-observers"
                          : "Medobservatører"
                      }}</span>
                      <input
                        type="text"
                        placeholder="Medobservatører"
                        v-model="collection.coObserver"
                        class="form-control"
                      />
                    </div>
                    <hr />
                    <h5 class="text-center mb-3">
                      {{ this.$englishPreferred ? "Weather" : "Vejret" }}
                    </h5>

                    <div class="mb-3 input-group">
                      <span class="input-group-text" id="wind-direction-text">{{
                        this.$englishPreferred
                          ? "Wind direction"
                          : "Vindretning"
                      }}</span>
                      <Multiselect
                        class="form-select"
                        v-model="collection.windDirection"
                        :options="windDirections"
                        placeholder="Vælg vindretning"
                        searchable="true"
                        ref="multiselect"
                      />
                    </div>

                    <div class="mb-3 input-group">
                      <span class="input-group-text"
                        >{{
                          this.$englishPreferred ? "Windforce" : "Vindstyrke"
                        }}
                        (m/s)</span
                      >
                      <input
                        type="number"
                        placeholder="Vindstyrke"
                        v-model="collection.windForce"
                        class="form-control"
                      />
                    </div>

                    <div class="mb-3 input-group">
                      <span class="input-group-text" id="cloud-cover-text">{{
                        this.$englishPreferred
                          ? "Cloud cover (1/8)"
                          : "Skydække (ottendedele)"
                      }}</span>
                      <Multiselect
                        class="form-select"
                        v-model="collection.cloudCover"
                        :options="cloudCovers"
                        placeholder="Skydække"
                        searchable="true"
                        ref="multiselect"
                      />
                    </div>

                    <div class="mb-3 input-group">
                      <span class="input-group-text">{{
                        this.$englishPreferred ? "Temperature" : "Temperatur"
                      }}</span>
                      <input
                        type="number"
                        placeholder="Temperatur"
                        v-model="collection.temperature"
                        class="form-control"
                      />
                    </div>

                    <div class="mb-3 input-group">
                      <span class="input-group-text" id="downpour-type-text">{{
                        this.$englishPreferred
                          ? "Precipitation type"
                          : "Nedbørstype"
                      }}</span>
                      <Multiselect
                        class="form-select"
                        v-model="collection.downpourType"
                        :options="downpourTypes"
                        placeholder="Vælg typen af nedbør"
                        searchable="true"
                        ref="multiselect"
                      />
                    </div>

                    <div class="mb-3 input-group">
                      <span class="input-group-text"
                        >{{
                          this.$englishPreferred
                            ? "Precipitation amount"
                            : "Nedbørsmægnde"
                        }}
                        (mm)</span
                      >
                      <input
                        type="number"
                        placeholder="Nedbørsmængde"
                        v-model="collection.downpour"
                        class="form-control"
                      />
                    </div>

                    <div class="mb-3 input-group">
                      <span class="input-group-text">{{
                        this.$englishPreferred
                          ? "Visibility (meters)"
                          : "Sigtbarhed (meter)"
                      }}</span>
                      <input
                        type="number"
                        placeholder="Sigtbarhed"
                        v-model="collection.visibility"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {{
                this.$englishPreferred
                  ? "Shown in DOFbasen under location"
                  : "Vises i DOFbasen under lokaliteten"
              }}: <br /><b
                >"<span v-if="collection != null">{{ collection.site.na }}</span
                >"</b
              >
            </div>
            <button
              @click="toggleShowChangeLocationOptions()"
              class="btn-xs btn-outline-secondary"
              :class="showChangeLocationOptions ? '' : 'mb-5'"
            >
              {{ this.$englishPreferred ? "Change" : "Skift" }}
            </button>
          </div>
        </div>
        <div class="row mt-2 mb-5" v-if="showChangeLocationOptions">
          <div class="col-8 text-center mb-5">
            <button
              @click="openNearestSitesModal"
              class="mb-3 btn btn-outline-primary form-control"
            >
              {{
                this.$englishPreferred
                  ? "Nearby locations"
                  : "Nærmeste lokaliteter"
              }}
            </button>
          </div>
          <div class="col-4 text-center">
            <button
              type="button"
              @click="openSiteMapModal()"
              class="btn mb-3 btn-primary form-control"
            >
              <i class="material-icons md-20 button-material-icon"> map </i>
              {{ this.$englishPreferred ? "Map" : "Kort" }}
            </button>
          </div>
        </div>
      </div>

      <div class="bottom-nav">
        <button
          type="button"
          class="btn btn-danger bottom-action"
          @click="showConfirmModal()"
        >
          <i class="material-icons button-material-icon"> delete </i>
          {{ this.$englishPreferred ? "Delete count" : "Slet tællingen" }}
        </button>
        <button
          type="button"
          class="btn btn-primary bottom-action"
          @click="updateCollection()"
        >
          <i class="material-icons button-material-icon"> save </i>
          {{ this.$englishPreferred ? "Save changes" : "Gem rettelser" }}
        </button>
      </div>
    </div>

    <!-- Nearest Sites Modal -->
    <div
      class="modal fade"
      id="nearestSitesModal"
      tabindex="-1"
      aria-labelledby="nearestSitesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="nearestSitesModalLabel">
              {{
                this.$englishPreferred
                  ? "Nearby locations"
                  : "Nærmeste lokaliteter"
              }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="nearestSites.length > 0">
              <div
                class="mb-2"
                v-for="(nearestSite, index) in nearestSites"
                :key="index"
                @click="selectNearestSite(nearestSite)"
              >
                {{ nearestSite.na }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Site Map Modal -->
    <div
      class="modal fade"
      id="siteMapModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="siteMapModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title" id="siteMapModalLabel">
              <h5>
                {{
                  this.$englishPreferred ? "Choose location" : "Vælg lokalitet"
                }}
              </h5>
              <span v-if="displaySiteChoice && siteToChoose != null">
                <br />
                {{ siteToChoose.na }}
                <button
                  @click="selectThisSite"
                  class="btn form-control btn-default"
                >
                  {{ this.$englishPreferred ? "Choose" : "Vælg" }}
                </button>
              </span>
            </div>
            <button
              type="button"
              @click="dismissSiteMapModal()"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <edit-collection-map
              v-if="this.collection != null"
              @show-site-map-choice="showSiteMapChoice"
              :isShowingSiteMapModal="isShowingSiteMapModal"
              :collectionForm="collection"
              :sites="sites"
            ></edit-collection-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import collectionRepository from "../idb/repositories/collectionRepository";
import NavHeader from "@/components/navbars/NavHeader.vue";
import observationRepository from "../idb/repositories/observationRepository";
import EditCollectionMap from "../components/collection/EditCollectionMap.vue";
import siteRepository from "../idb/repositories/siteRepository";
import EditPositionMap from "../components/collection/EditPositionMap.vue";
import countPointsRepository from "../idb/repositories/countPointsRepository";
import Multiselect from "@vueform/multiselect";
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import axios from "axios";
import { toRaw } from "vue";
import { Modal } from "bootstrap";
import ConfirmModal from "../components/ConfirmModal.vue";

delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    NavHeader,
    Multiselect,
    EditCollectionMap,
    EditPositionMap,
    ConfirmModal,
  },
  data() {
    return {
      mymap: null,
      marker: null,
      markerIsSet: false,
      showOptionals: false,
      showChangeLocationOptions: false,
      showEditPositionMap: false,
      nearestSites: [],
      nearestSitesModal: null,
      siteMapModal: null,
      isShowingSiteMapModal: false,
      displaySiteChoice: false,
      siteToChoose: null,
      sites: [],
      isEditPositionMap: false,
      windDirections: [
        "Nord (N)",
        "Nord-Nordøst (NNØ)",
        "Nordøst (NØ)",
        "Nordvest (NV)",
        "Nord-Nordvest (NNV)",
        "Øst-Nordøst (ØNØ)",
        "Øst (Ø)",
        "Øst-Sydøst (ØSØ)",
        "Sydøst (SØ)",
        "Syd-Sydøst (SSØ)",
        "Syd (S)",
        "Syd-Sydvest (SSV)",
        "Sydvest (SV)",
        "Vest-Sydvest (VSV)",
        "Vest (V)",
      ],
      cloudCovers: [...Array(9).keys()],
      downpourTypes: ["Regn", "Hagl", "Slud", "Sne"],
      collection: null,
      navTitle: this.$englishPreferred ? "Edit tour data" : "Ret turdata",
      currentTab: "Mandatory",
      tabs: [
        { enTitle: "Mandatory", daTitle: "Påkrævet" },
        { enTitle: "Optional", daTitle: "Valgfrit" },
      ],
      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
  },
  methods: {
    showConfirmModal() {
      this.modalTitle = "Slet tælling";
      this.modalMessage =
        "Er du sikker på at du vil slette denne tælling og dens observationer?";
      this.showModal = true;
    },
    onConfirmed() {
      // Handle the OK button click here
      // This function is called when the user clicks "OK" in the modal
      this.showModal = false;

      this.deleteCollection();
    },
    onDeclined() {
      this.showModal = false;
    },
    switchToMainEdit() {
      this.showEditPositionMap = false;
      this.mymap.setView(
        [this.collection.latitude, this.collection.longitude],
        18
      );
      this.setNewMarker(this.collection.latitude, this.collection.longitude);
    },
    selectThisSite() {
      this.collection.site = this.siteToChoose;
      this.closeSiteMapModal();
    },
    dismissSiteMapModal() {
      this.isShowingSiteMapModal = false;
      this.displaySiteChoice = false;
      this.siteTotChoose = null;
    },
    showSiteMapChoice(site) {
      this.displaySiteChoice = true;
      this.siteToChoose = site;
    },
    closeSiteMapModal() {
      this.siteMapModal.hide();
      this.isShowingSiteMapModal = false;
    },
    openSiteMapModal() {
      this.siteMapModal = new Modal(
        document.getElementById("siteMapModal"),
        {}
      );
      this.siteMapModal.show();

      this.isShowingSiteMapModal = true;
    },
    setEndTime() {
      this.collection.endTime = new Date(
        new Date("1970/01/01 " + this.collection.startTime).getTime() +
          5 * 60000
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
    updateCollection() {
      delete this.collection.observations;

      // First mark collection as not synched and then update through api
      this.collection.unsynched = true;
      let unwrappedCollection = {
        ...JSON.parse(JSON.stringify(this.collection)),
      };
      // Check if this collection has apiId
      if (this.collection.apiId) {
        collectionRepository.insertCollection(unwrappedCollection).then(() => {
          // Update collection in API, and when done, update locally as well
          axios
            .put(
              "/spontan/collections/" + this.collection.apiId,
              unwrappedCollection
            )
            .then((response) => {
              console.log(
                "Successfully updated in DOFbasen, now updating in local database"
              );
              // Success, now update locally with unsynched marked as true
              unwrappedCollection.unsynched = false;
              collectionRepository.insertCollection(unwrappedCollection);
              this.$router.push("/");
            })
            .catch((error) => {
              console.log("Server did not respond, updating locally");
              collectionRepository.insertCollection(unwrappedCollection);
              this.$router.push("/");
            });
        });
      } else {
        collectionRepository.insertCollection(unwrappedCollection);
        this.$router.push("/");
      }
    },
    openNearestSitesModal() {
      this.nearestSites = [];
      this.getNearestSites();
      this.nearestSitesModal = new Modal(
        document.getElementById("nearestSitesModal"),
        {}
      );
      this.nearestSitesModal.show();
    },
    selectNearestSite(nearestSite) {
      this.collection.site = nearestSite;
      this.nearestSitesModal.hide();
    },
    getNearestSites() {
      // Get the 15 nearest sites and insert as array into this.nearestSites
      let count = 0;
      let currentMaximumDistance = 0;
      let currentSecondMaximumDistance = 0;
      const currentPositionLat = this.collection.latitude;
      const currentPositionLng = this.collection.longitude;
      // For hver site
      this.sites.forEach((site) => {
        count++;
        // Få fat i distance i meter fra turens position
        let distanceInMeters = this.getDistance(
          site.la,
          site.lo,
          currentPositionLat,
          currentPositionLng
        );
        site.distanceInMeters = distanceInMeters;
        // Hvis der allerede er 10 lokaliteter tilføjer:
        if (count >= 16) {
          if (distanceInMeters < currentMaximumDistance) {
            let furthestAwaySite = this.nearestSites.reduce(function (
              prev,
              curr
            ) {
              return prev.distanceInMeters > curr.distanceInMeters
                ? prev
                : curr;
            });
            this.nearestSites = this.nearestSites.filter((newArraySite) => {
              return newArraySite.na !== furthestAwaySite.na;
            });
            let secondFurthestAwaySite = this.nearestSites.reduce(function (
              prev,
              curr
            ) {
              return prev.distanceInMeters > curr.distanceInMeters
                ? prev
                : curr;
            });
            this.nearestSites.push(site);

            if (distanceInMeters > currentSecondMaximumDistance) {
              currentMaximumDistance = distanceInMeters;
            }
            currentMaximumDistance = secondFurthestAwaySite.distanceInMeters;
          }
          // Hvis ikke der allerede er 10 lokaliteter tilføjet:
        } else {
          this.nearestSites.push(site);
          if (distanceInMeters > currentMaximumDistance) {
            currentSecondMaximumDistance = currentMaximumDistance;
            currentMaximumDistance = distanceInMeters;
          }
        }
      });
    },
    toRadian(degree) {
      return (degree * Math.PI) / 180;
    },
    getDistance(originLat, OriginLng, destinationLat, destinationLng) {
      // return distance in meters
      let radianLngOrigin = this.toRadian(OriginLng);
      let radianLatOrigin = this.toRadian(originLat);
      let radianLngDestination = this.toRadian(destinationLng);
      let radianLatDestination = this.toRadian(destinationLat);

      let deltaLat = radianLatOrigin - radianLatDestination;
      let deltaLng = radianLngOrigin - radianLngDestination;

      var a =
        Math.pow(Math.sin(deltaLat / 2), 2) +
        Math.cos(radianLatOrigin) *
          Math.cos(radianLatDestination) *
          Math.pow(Math.sin(deltaLng / 2), 2);
      var c = 2 * Math.asin(Math.sqrt(a));
      var EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    },
    changePosition() {
      this.showEditPositionMap = true;
    },
    async deleteCollection() {
      // Check if collection has apiId
      if (this.collection.apiId) {
        // First mark collection as deleted and then update in local database
        this.collection.deleted = true;
        let unwrappedCollection = {
          ...JSON.parse(JSON.stringify(this.collection)),
        };
        collectionRepository.insertCollection(unwrappedCollection).then(() => {
          // Delete collection in API, and when done, delete locally as well
          axios
            .delete("/spontan/collections/" + this.collection.apiId)
            .then((response) => {
              this.deleteCollectionLocally();
              this.$router.push("/");
            })
            .catch((error) => {
              console.log("Server did not respond, don't delete locally yet");
              this.$router.push("/");
            });
        });
      } else {
        await this.deleteCollectionLocally();
        this.$router.push("/");
      }
    },
    async deleteCollectionLocally() {
      // First delete all observations of this collection
      if (this.collection.observations != null) {
        this.collection.observations.forEach((observation) => {
          observationRepository.deleteObservationById(observation.id);
        });
      }
      // Then delete any points given
      countPointsRepository.deleteCountPointsByCollectionId(this.collection.id);

      // Then delete the collection
      collectionRepository.deleteCollectionById(this.collection.id);

      // Then clear cache for collection, observation, and countpoint data:
      const cache = await caches.open("spontanCache");
      await cache.delete("/collections");
      await cache.delete("/observations");
      await cache.delete("/countPoints");
      console.log("deleted collection and cleared cache");
    },
    async getCollection() {
      collectionRepository
        .getCollection(this.$route.params.id)
        .then((value) => this.prepareCollection(value));
    },
    async prepareCollection(collection) {
      this.collection = collection;
      observationRepository
        .getObservationsByCollectionId(this.$route.params.id)
        .then(
          (value) => (this.collection.observations = value),
          this.createMap()
        );
    },
    toggleOptionals() {
      this.showOptionals = !this.showOptionals;
    },
    toggleShowChangeLocationOptions() {
      this.showChangeLocationOptions = !this.showChangeLocationOptions;
    },
    createMap() {
      this.mymap = leaflet
        .map("tinymapid", {
          center: [0, 0],
          dragging: false,
          scrollWheelZoom: "center",
        })
        .setView([this.collection.latitude, this.collection.longitude], 18);

      leaflet.tileLayer
        .wms("https://service.dofbasen.dk/geoserver/dof/wms", {
          layers: "dof:orto_foraar_wms",
          format: "image/png",
          attribution:
            '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering',
        })
        .addTo(toRaw(this.mymap));
      this.getSites();
      this.setNewMarker(this.collection.latitude, this.collection.longitude);
    },
    setNewMarker(lat, lng) {
      if (this.marker != null) {
        this.mymap.removeLayer(this.marker);
        this.marker = null;
      }
      this.marker = leaflet.marker([lat, lng]).addTo(toRaw(this.mymap));
    },
    async getSites() {
      this.sites = await siteRepository.getSites();
    },
  },
  beforeMount() {
    this.getCollection();
  },
};
</script>

<style scoped>
#tinymapid {
  height: 220px;
}

.modal-title {
  width: 100%;
}
</style>
