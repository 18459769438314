<template>
  <div>
    <div class="center">
      <h2>{{ $englishPreferred ? "Edit position" : "Ret placering"}}</h2>
    </div>

    <div>
      <div class="map-marker-centered"></div>
      <div id="map"></div>
    </div>

    <div class="bottom-nav">
      <button
        @click="getLocation()"
        type="button"
        class="bottom-action btn btn-primary map-button"
      >
        <i class="material-icons button-material-icon"> gps_fixed </i>
        {{ $englishPreferred ? "My position" : "Min placering"}}
      </button>
      <button
        type="button"
        class="bottom-action btn btn-default map-button"
        v-on:click="$emit('switch-to-main-edit')"
      >
        <i class="material-icons button-material-icon"> done </i>
        OK
      </button>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import leaflet from "leaflet";
import { toRaw } from "vue";
delete leaflet.Icon.Default.prototype._getIconUrl;
leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  props: ["collectionForm"],
  data() {
    return {
      wasLocationAlreadySet: false,
      map: null,
      showInstructions: true,
      gpsOptions: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
      radiusCircle: null,
      ortoPreferred: false,
    };
  },
  methods: {
    isLocationAlreadySet() {
      if (
        this.collectionForm.latitude != null &&
        this.collectionForm.longitude != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    createMap() {
      this.wasLocationAlreadySet = this.isLocationAlreadySet();
      let latitude = this.isLocationAlreadySet()
        ? this.collectionForm.latitude
        : 55.993;
      let longitude = this.isLocationAlreadySet()
        ? this.collectionForm.longitude
        : 10.515;
      let zoomLevel = 7;
      if (this.isLocationAlreadySet()) {
        zoomLevel = 17;
      }

      this.map = leaflet.map("map").setView([latitude, longitude], zoomLevel);

      var openStreetMap = leaflet.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            '<small><a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://www.openstreetmap.org/copyright">CC-BY-SA</a></small>',
          maxZoom: 18,
          id: "openstreetmap",
        }
      );

      var ortofotoLayer = leaflet.tileLayer.wms(
        "https://service.dofbasen.dk/geoserver/dof/wms",
        {
          layers: "dof:orto_foraar_wms",
          format: "image/png",
          attribution:
            '&copy; <a target="_blank" href="https://download.kortforsyningen.dk/content/vilk%C3%A5r-og-betingelser">Styrelsen for Dataforsyning og Effektivisering',
        }
      );

      var baseLayers = {
        "Datafordeler Ortofoto": ortofotoLayer,
        OpenStreetMap: openStreetMap,
      };

      // Add layer control to map
      this.baseLayers = leaflet.control
        .layers(baseLayers)
        .addTo(toRaw(this.map));

      leaflet.control.scale({ imperial: false }).addTo(toRaw(this.map));

      this.map.on("moveend", (e) => {
        this.mapMoveend();
      });

      if (this.ortoPreferred == true) {
        ortofotoLayer.addTo(toRaw(this.map));
      } else {
        openStreetMap.addTo(toRaw(this.map));
      }
    },
    setNewRadiusCircle(lat, lng, radius) {
      this.clearRadiusCircle();
      this.radiusCircle = leaflet
        .circle([lat, lng], radius, { color: "#1f7aaf" })
        .addTo(this.map);
    },
    clearRadiusCircle() {
      if (this.radiusCircle != undefined) {
        this.map.removeLayer(this.radiusCircle);
      }
    },
    mapMoveend() {
      let lat = this.map.getCenter().lat;
      let lng = this.map.getCenter().lng;
      this.collectionForm.latitude = lat;
      this.collectionForm.longitude = lng;
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.gpsError,
          this.gpsOptions
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },
    gpsError() {
      console.log("Failed to find gps position");
    },
    showPosition(position) {
      let zoomLevel = this.map.getZoom() < 17 ? 17 : this.map.getZoom();
      let accuracy = position.coords.accuracy;
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;
      this.map.flyTo([latitude, longitude], zoomLevel);
      this.setNewRadiusCircle(latitude, longitude, accuracy);
    },
  },
  mounted: function () {
    if ($cookies.isKey("ortoPreferred")) {
      this.ortoPreferred = true;
    }
    this.createMap();
  },
};
</script>

<style scoped>
.center {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.map-marker-centered {
  background-image: url("~@/assets/crosshairs-gps.png");
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 2;
  left: calc(50% - 24px);
  top: calc(50% + 4px);
  transition: all 0.4s ease;
  opacity: 0.6;
}
</style>
