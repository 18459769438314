import idb from "../idb.js";

export default {
  async insertCountPoints(countPoints) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["countPoints"], "readwrite");
      let store = trans.objectStore("countPoints");

      store.put(countPoints);
    });
  },

  async getCountPointsCount() {
    let count = await idb.getCountByObjectStore("countPoints");
    return count.result;
  },

  async getCountPointsByCollectionId(collectionId) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["countPoints"], "readonly");
      trans.oncomplete = () => {
        resolve(countPoints);
      };

      let store = trans.objectStore("countPoints");
      let countPoints = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.collectionId == collectionId) {
            countPoints.push(cursor.value);
          }
          cursor.continue();
        }
      };
    });
  },

  async deleteCountPointsByCollectionId(collectionId) {
    let countPoints = await this.getCountPointsByCollectionId(collectionId);

    if (countPoints[0] != null && countPoints[0].collectionId != null) {
      console.log("deleting countPoints by collectionId: " + collectionId);

      let db = await idb.getDb();

      return new Promise((resolve) => {
        db.transaction("countPoints", "readwrite")
          .objectStore("countPoints")
          .delete(+countPoints[0].id);
      });
    }
  },

  async getCountPoints() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["countPoints"], "readonly");
      trans.oncomplete = () => {
        resolve(countPoints);
      };

      let store = trans.objectStore("countPoints");
      let countPoints = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          countPoints.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async getCountPointsByAtlasSquareNumber(atlasSquareNumber) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["countPoints"], "readonly");
      trans.oncomplete = () => {
        resolve(countPoints);
      };

      let store = trans.objectStore("countPoints");
      let countPoints = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.kvadratnr == atlasSquareNumber) {
            countPoints.push(cursor.value);
          }
          cursor.continue();
        }
      };
    });
  },
};
